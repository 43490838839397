import React, { useRef, useState } from "react"
import axios from "axios"
import { Auth } from "aws-amplify";
import ReactPlayer from "react-player/youtube"
import { Button, Chip, TableCell, TableRow } from "@mui/material";
import VideoIcon from '@mui/icons-material/YouTube';
import FileIcon from '@mui/icons-material/Article';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const FileTile = ({ fileInfo, testId, facId, classId }) => {
  const [stats, setStats] = useState({duration: 0, durationPercent: 0, prevDuration: 0, totalDuration: 0, playbackRate: 1, viewed: false})
  // const [prevDuration, setPrevDuration] = useState(0)
  const reactPlayerRef = useRef()

  const OnProgress = (e) => {
    // let rate = reactPlayerRef.current.getInternalPlayer().getPlaybackRate()
    // console.log(rate)
    let playedSeconds = Math.floor(e.playedSeconds)
    let seconds = playedSeconds - stats.prevDuration
    // console.log(e)
    // console.log(prevDuration)
    // console.log(playedSeconds)
    // console.log(seconds)
    if(seconds > 0 && seconds <= (5 * stats.playbackRate)){   // *5 because the function interval is 5 seconds
      setStats(prev => ({
        ...prev, 
        duration: prev.duration + seconds, 
        durationPercent: prev.duration + seconds ? Math.round((prev.duration + seconds) * 100 / stats.totalDuration * 100)/100 : 0
      }))
      if(!fileInfo.viewed && !stats.viewed && (stats.duration + seconds ? Math.round((stats.duration + seconds) * 100 / stats.totalDuration * 100)/100 : 0) > 75){
        
        Auth.currentSession()
        .then((data) => {
          const jwtToken = data.idToken.jwtToken;
          
          const addViewCount = async(testId, facId, classId, fileId) => {
            try {
              const response =  await axios.post("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/facilitator/"+facId+"/file",
                JSON.stringify({
                  "testId": testId,
                  "classId": classId,
                  "fileId": fileId
                }),
                { 
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': jwtToken
                }
              });
              console.log("api file post response: ", response.data)
            }
            catch (error) {
              console.log(error)
            }
          }

          addViewCount(testId, facId, classId, fileInfo.fileId)

        })
        .catch((err) => console.log(err));
        
        setStats(prev => ({...prev, viewed: true}))
      } 
    }
    setStats(prev => ({...prev, prevDuration: playedSeconds}))
  }

  const onDuration = (e) => {
    // console.log(reactPlayerRef.current)
    // console.log(reactPlayerRef.current.getInternalPlayer())
    setStats(prev => ({...prev, totalDuration: e}))
    // console.log("Total Duration: ", e)
  }
  const onPlaybackRateChange = (e) => {
    setStats(prev => ({...prev, playbackRate: e}))
    console.log("Playback Rate: ", e)
  }
  const onEnded = () => {
    // console.log("File Ended")
    setStats(prev => ({...prev, duration: 0, durationPercent: 0, prevDuration: 0}))
  }

  const downloadFile = () => {
    window.open(fileInfo.url.includes("www.youtube.com") ? fileInfo.driveURL : fileInfo.url, "_blank")
    if(!fileInfo.viewed && !stats.viewed){
      Auth.currentSession()
      .then((data) => {
        const jwtToken = data.idToken.jwtToken;
        
        const addViewCount = async(testId, facId, classId, fileId) => {
          try {
            const response =  await axios.post("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/facilitator/"+facId+"/file",
              JSON.stringify({
                "testId": testId,
                "classId": classId,
                "fileId": fileId
              }),
              { 
              headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
              }
            });
            console.log("api file post response: ", response.data)
          }
          catch (error) {
            console.log(error)
          }
        }
  
        addViewCount(testId, facId, classId, fileInfo.fileId)
  
      })
      .catch((err) => console.log(err));

      setStats(prev => ({...prev, viewed: true}))
    }
  }

  return (
    <TableRow 
      hover 
      {...(fileInfo.viewed && !fileInfo.category) && {style: { backgroundColor: "rgb(215 215 215 / 40%)" }}} 
      {...(fileInfo.category===1) && {style: { backgroundColor: "rgb(255 181 181 / 30%)" }}}
      // {...(fileInfo.category===1 && !fileInfo.viewed) && {style: { backgroundColor: "rgb(255 181 181 / 30%)" }}}
      // {...(fileInfo.category===1 && fileInfo.viewed) && {style: { backgroundColor: "rgb(255 181 181 / 70%)" }}}
      {...(fileInfo.category===2) && {style: { backgroundColor: "rgb(170 234 203 / 30%)" }}}
      // {...(fileInfo.category===2 && !fileInfo.viewed) && {style: { backgroundColor: "rgb(170 234 203 / 30%)" }}}
      // {...(fileInfo.category===2 && fileInfo.viewed) && {style: { backgroundColor: "rgb(170 234 203 / 70%)" }}}
    >
      <TableCell style={{padding: "12px"}}>
        <h4>{fileInfo.title}
         {fileInfo.viewed && <><br/><Chip sx={{mt: "0.5rem"}} icon={<CheckCircleIcon/>} color='success' label="Viewed/Downloaded" /></>}
        </h4>
      </TableCell>
      {/* <TableCell align="center" style={{padding: "12px"}}><p>{fileInfo.lang === "EN" ? "English" : "Hindi"}</p></TableCell> */}
      {fileInfo.url.includes("www.youtube.com") ? 
        <>
          <TableCell style={{padding: "12px"}}>
            <ReactPlayer 
              // height={"100%"}
              height={"155px"}
              width={"350px"}
              ref={reactPlayerRef}
              // config={{
                //   youtube: {
                  //     playerVars: {
                    //       origin: window.location.origin
              //     }
              //   }
              // }}
              url={fileInfo.url}    // URL example - https://www.youtube.com/embed/30Oy4Jr2zmg
              // muted={true}
              // playing={true}
              controls={true}
              progressInterval={5000}    // Interval at which the below functions are called
              onProgress={OnProgress}
              onDuration={onDuration}
              onEnded={onEnded}
              onPlaybackRateChange={onPlaybackRateChange}
            />
          </TableCell>
          <TableCell align="center" style={{padding: "12px"}}>
            {fileInfo.driveURL ?    // URL example - https://drive.usercontent.google.com/download?id=13ck8_1YNODPf7YJ_PzNTzCA_f0W1M0yA&export=download
              <Button variant="contained" color="info" sx={{width: "200px"}} startIcon={<VideoIcon/>} onClick={downloadFile}>
                Download Video
              </Button>
              : null
            }
          </TableCell>
        </>
      :
        <>
          <TableCell align="center" style={{padding: "12px"}}>-</TableCell>
          <TableCell style={{padding: "12px"}}>
            <Button variant="contained" color="info" sx={{width: "200px"}} startIcon={<FileIcon/>} onClick={downloadFile}>
              Download File
            </Button>
          </TableCell>
        </> 
      }
      {/* <TableCell align="center" style={{padding: "12px"}}><p>{fileInfo.viewed ? "Yes" : "No"}</p></TableCell> */}
      {/* <p>Total Duration: {stats.totalDuration}s</p> */}
      {/* <p>Duration Viewed: {stats.duration}s</p> */}
      {/* <p>Percent Duration Viewed: {stats.durationPercent}%</p> */}
      {/* <p>Is Viewed: {stats.viewed ? "true" : "false"}</p> */}
      {/* <p>{prevDuration}</p> */}
    </TableRow>
  )
}

export default FileTile