import React, { useState, useEffect } from "react";
import { Button, Divider, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios"
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import FilterListIcon from '@mui/icons-material/FilterList';
// import AbcIcon from '@mui/icons-material/Abc';
// import SchoolIcon from '@mui/icons-material/School';
// import PinIcon from '@mui/icons-material/Pin';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Table from "../../TableComponent";
import DeleteStudents from "./DeleteStudents";

const Students = ({adminInfo}) => {
  const [studentsData, setStudentsData] = useState([])
  
  useEffect(() => {
    Auth.currentSession()
    .then((data) => {
      const jwtToken = data.idToken.jwtToken;
      
      const getStudentsData = async(adminId, schoolId) => {
        try {
          const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/admin/"+adminId+"/students?schoolId="+schoolId, {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': jwtToken
              }
          });
          // console.log("Students Data: ",response.data["students"])
          // adding full name by combining firstName and lastName
          const studentsData = response.data["students"].map(item => ({
            ...item, 
            "fullName": item["firstName"] + " " + item["lastName"]
          }))
          setSchool(adminInfo.admin.schools[0].id)
          setStudentsData(studentsData)
        }
        catch (error) {
            console.log(error)
        }
      }

      getStudentsData(adminInfo.admin.adminId, adminInfo.admin.schools[0].id)
      
    })
    .catch((err) => console.log(err));
    
  }, [adminInfo]);
  
  const columns = [
    {
      key: "fullName",
      headerName: "Student Name",
      width: 80
    },
    {
      key: "studId",
      headerName: "Student PEN",
      width: 80
    },
    {
      key: "std",
      headerName: "Class",
      width: 30
    },
    { 
      key: "divn", 
      headerName: "Section", 
      width: 30 
    },
    { 
      key: "rollNo", 
      headerName: "Roll No", 
      width: 30 
    },
    { 
      key: "gender", 
      headerName: "Gender", 
      width: 30 
    },
    { 
      key: "options", 
      headerName: "", 
      width: 30,
      options: "students",
      test: adminInfo.test
    }
  ];

  const headers = [
    { label: "Class", key: "std" },
    { label: "Section", key: "divn" },
    { label: "Student Name", key: "fullName" },
    { label: "Gender", key: "gender" },
    { label: "Student PEN (Maximum 12 digit)", key: "studId"},
    { label: "Roll No", key: "rollNo" }
  ];

  const orderBy = [];
  const [std, setStd] = useState("");
  const [divn, setDivn] = useState("");
  const [school, setSchool] = useState("");
  
  const handleStdChange = (e) => {
    setStd(e.target.value);
  };
  
  const handleDivnChange = (e) => {
    setDivn(e.target.value);
  };

  const handleSchoolChange = (e) => {
    setStd("");
    setDivn("");
    setStudentsData([])
    setSchool(e.target.value);
    if(e.target.value!==""){
      Auth.currentSession()
      .then((data) => {
        const jwtToken = data.idToken.jwtToken;
        
        const getStudentsData = async(adminId, schoolId) => {
          try {
            const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/admin/"+adminId+"/students?schoolId="+schoolId, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                }
            });
            // console.log("Students Data: ",response.data["students"])
            // adding full name by combining firstName and lastName
            const studentsData = response.data["students"].map(item => ({
              ...item, 
              "fullName": item["firstName"] + " " + item["lastName"]
            }))
            setStudentsData(studentsData)
          }
          catch (error) {
            console.log(error)
          }
        }
        getStudentsData(adminInfo.admin.adminId, e.target.value)
      })
      .catch((err) => console.log(err));
    }
  };

  // const handleStdChipDelete = () => {
  //   setStd("");
  // };
  
  // const handleDivnChipDelete = () => {
  //   setDivn("");
  // };

  // const handleSchoolChipDelete = () => {
  //   setStd("");
  //   setDivn("");
  //   setSchool("");
  //   setStudentsData([])
  // };

  var filteredStudentsData
  if(std==="" && divn===""){
    filteredStudentsData = studentsData
    // filteredSchoolFacInfo
  }
  else if(std!=="" && divn===""){
    filteredStudentsData = [...studentsData.filter(item => item.std === std)]
  }
  else if(std==="" && divn!==""){
    filteredStudentsData = [...studentsData.filter(item => item.divn === divn)]
  }
  else{
    filteredStudentsData = [...studentsData.filter(item => item.std === std && item.divn === divn)]
  }
  // console.log(studentsData)
  // console.log(filteredStudentsData)

  return (
    <div className="studentsContent innerContent">
      <h2 style={{textAlign: "center", color: "#0288D1", borderRadius: "15px", border: "1px solid"}}>Students Data{adminInfo.test ? <span> - {adminInfo.test.testName}</span>: ""}</h2>
      {(adminInfo.test && adminInfo.test.status===0) && 
        <h4 style={{paddingTop:"1rem", color: "#dc3545"}}>
          Add students using the "Student list upload" button on the left. Last date to finalize student list is {new Date(adminInfo.test.tentativeStartDate).toLocaleDateString("en-GB", { dateStyle: "long" })}. If some student details on UDISE are not available till {new Date(adminInfo.test.tentativeStartDate).toLocaleDateString("en-GB", { day: "2-digit", month: "long" })}, proceed to add only those students whose details are available.
        </h4>
      }
      <div className="filters">
        <div className="forms">
          <h4 style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem"}}>
            <FilterListIcon fontSize="small"/>
            <span style={{height: "100%"}}>Filters:</span> 
          </h4>
          <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
            <InputLabel id="schoolFilterLabel">School</InputLabel>
            <Select
              labelId="schoolFilterLabel"
              id="schoolFilter"
              value={school}
              onChange={handleSchoolChange}
              autoWidth
              label="School Name"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {/* getting all unique divn values from data */}
              { adminInfo &&
                adminInfo.admin.schools.map((item, i) => {
                  return <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 85}} size="small">
            <InputLabel id="stdFilterLabel">Class</InputLabel>
            <Select
              labelId="stdFilterLabel"
              id="stdFilter"
              value={std}
              onChange={handleStdChange}
              autoWidth
              label="Class"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {/* getting all unique std values from data */}
              {divn==="" &&
                [...new Set(studentsData.map(item => item.std))].sort().map((item, i) => {
                return <MenuItem key={i} value={item}>{item}</MenuItem>
                })
              }
              {divn!=="" &&
                [...new Set(studentsData.filter(item => item.divn === divn).map(item => item.std))].sort().map((item, i) => {
                return <MenuItem key={i} value={item}>{item}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
            <InputLabel id="divnFilterLabel">Section</InputLabel>
            <Select
              labelId="divnFilterLabel"
              id="divnFilter"
              value={divn}
              onChange={handleDivnChange}
              autoWidth
              label="Section"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {/* getting all unique divn values from data */}
              {std==="" &&
                [...new Set(studentsData.map(item => item.divn))].sort().map((item, i) => {
                return <MenuItem key={i} value={item}>{item}</MenuItem>
                })
              }
              {std!=="" &&
                [...new Set(studentsData.filter(item => item.std === std).map(item => item.divn))].sort().map((item, i) => {
                return <MenuItem key={i} value={item}>{item}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </div>
        {/* {(std || divn || school) &&
          <div className="currentFilters">
            <Typography variant="body1" gutterBottom>
              Current Filters: 
            </Typography>
            {school && 
              <Chip 
                variant="outlined" 
                color="info"  
                onDelete={handleSchoolChipDelete} 
                icon={<SchoolIcon />}
                label={adminInfo.admin.schools.filter(item => item.id === school)[0].name}
              />
            }
            {std && 
              <Chip 
                variant="outlined" 
                color="info"  
                onDelete={handleStdChipDelete} 
                icon={<PinIcon />}
                label={std}
              />
            }
            {divn &&
              <Chip 
                variant="outlined" 
                color="info"  
                onDelete={handleDivnChipDelete} 
                icon={<AbcIcon />}
                label={divn} 
              />
            }
          </div>
        } */}
      </div>
      <div className="tabs">
        <Button variant="contained" color="info" startIcon={<FileDownloadIcon />} sx={{ placeSelf: "start" }}>
          <CSVLink data={studentsData} headers={headers} filename={"StudentsDataAdmin.csv"}>
            Download as CSV
          </CSVLink>
        </Button>
        {school && <DeleteStudents adminInfo={adminInfo} schoolId={school}/>}
      </div>
      <Divider />
      <div className="table">
        <Table
          columns={columns}
          tableData={filteredStudentsData}
          orderBy={orderBy}
        />
      </div>
    </div>
  );
};

export default Students;
