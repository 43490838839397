import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga4';
import axios from "axios";
import { Auth } from "aws-amplify";
import { AppBar, CircularProgress, FormControl, InputLabel, MenuItem, Select, Tab, Tabs } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
// import TranslateIcon from '@mui/icons-material/Translate';
// import PinIcon from '@mui/icons-material/Pin';
// import SchoolIcon from '@mui/icons-material/School';
// import EditNoteIcon from '@mui/icons-material/EditNote';

import CompareReport from './CompareReport';
import BarChart from '../../BarChart';

const Report = ({ adminInfo }) => {
  const [reportData, setReportData] = useState("")
  const [reportError, setReportError] = useState("")
  const [testId, setTestId] = useState(adminInfo.allTests ? adminInfo.allTests[0].testId : null)
  
  useEffect(() => {
    if(adminInfo.allTests) {
      Auth.currentSession()
      .then((data) => {
        const jwtToken = data.idToken.jwtToken;
        
        const getReportData = async(adminId, testId) => {
          try {
            const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/admin/"+adminId+"/result?testId="+testId, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                }
            });
            // console.log("Report Data: ",response.data["report"])
            setReportData(response.data["report"])
            setStd(response.data["report"].school.map(item => item.std).sort()[0] || "")
            setSchool(response.data["report"].school.map(item => item.schoolName).sort()[0] || "")
            if(!response.data["report"].school.length) setReportError("Selected test does not contain results for your school!") 
          }
          catch (error) {
            // console.log(error)
            if(error.response.status === 404) setReportError("Selected test does not contain results for your school!")
            else console.log(error)
          }
        }
  
        getReportData(adminInfo.admin.adminId, testId)
        
      })
      .catch((err) => console.log(err));
    }
    else{
      alert("No Test has been completed yet!\nRedirecting to Dashboard")
      window.location.replace("/");
    }
    
  }, [adminInfo, testId]);

  const [tabValue, setTabValue] = useState(0);
  const [std, setStd] = useState("");  
  // hard coded lang to hindi on testId 5
  const [lang, setLang] = useState(testId!==5 ? "English" : "Hindi");
  const [school, setSchool] = useState("");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    // console.log(event.target.firstChild.data)
    ReactGA.event({
      category: 'Admin Results Tab',
      action: 'Result Tab Switch',
      label: event.target.firstChild.data
    });
  };

  const handleTestIdChange = (e) => {
    setStd("");
    setSchool("");
    setReportData("");
    setReportError("")
    setTestId(e.target.value);
  };

  const handleStdChange = (e) => {
    setStd(e.target.value);
  };
  
  const handleLangChange = (e) => {
    setLang(e.target.value);
  };
  
  const handleSchoolChange = (e) => {
    setSchool(e.target.value);
  };
  
  // const handleStdChipDelete = () => {
  //   setStd("");
  // };
  
  // const handleSchoolChipDelete = () => {
  //   setSchool("");
  // };

  var filteredReportData = reportData
  if(std!== "") {
    filteredReportData = {
      "allSchools": reportData.allSchools.filter(item => item.std === std)[0],
      "region": reportData.region.filter(item => item.std === std)[0],
      "school": reportData.school.filter(item => item.std === std),
      "thresholds": reportData["thresholds"].filter(item => item.std === std)[0]
    }
  }
    
  return (
    <div className='report innerContent'>
      <h2 style={{textAlign: "center", color: "#0288D1", borderRadius: "15px", border: "1px solid"}}>Results</h2>
      <div className="chartArea">
        <div >
          <AppBar position="static" sx={{mt: "0.5rem", backgroundColor: "#001162"}}>
            <Tabs value={tabValue} onChange={handleTabChange} sx={{minHeight: "30px"}} variant='fullWidth' textColor="inherit" TabIndicatorProps={{style: {backgroundColor: "#7ed46a", height: "4px"}}}>
              <Tab label="All Schools" sx={{fontSize: "inherit", minHeight: "30px"}}/>
              <Tab label="Your School" sx={{fontSize: "inherit", minHeight: "30px"}}/>
              <Tab label="Compare" sx={{fontSize: "inherit", minHeight: "30px"}}/>
            </Tabs>
          </AppBar>
          { tabValue !== 2 ?
              <div style={{display: "flex", flexDirection: "column", paddingTop: "0.5rem"}}>
                {/* <h4 style={{color: "#dc3545"}}>Note: Invalid attempts are not considered in the results.</h4> */}
                <p style={{fontSize: "14px", color: "#dc3545"}}>
                  Results generated based on recordings received till {adminInfo.allTests && 
                    new Date(adminInfo.allTests.filter(item=> item.testId === testId)[0].uploadEndDate).toLocaleDateString("en-GB", { dateStyle: "long" })}
                </p>
              </div>
            :
              <div style={{display: "flex", flexDirection: "column", paddingTop: "0.5rem"}}>
                <h4 style={{color: "#dc3545"}}>Note: Students considered for comparison have valid attempts in both tests & were in the same school, class & section in both tests (Transferred students are not considered)</h4>
              </div>
          }
        </div>
        { tabValue === 2 ?
          <CompareReport adminInfo={adminInfo}/>
        :
          <>
            <div className="filters">
              <div className="forms">
                <h4 style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem"}}>
                  <FilterListIcon fontSize="small"/>
                  <span style={{height: "100%"}}>Filters:</span> 
                </h4>
                <FormControl sx={{ m: 1, minWidth: 150}} size="small">
                  <InputLabel id="testNameFilterLabel">Test Name</InputLabel>
                  <Select
                    labelId="testIdFilterLabel"
                    id="testIdFilter"
                    value={testId ? testId : ""}
                    onChange={handleTestIdChange}
                    autoWidth
                    label="Test Name"
                  >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem> */}
                    {/* getting all unique std values from data */}
                    {adminInfo.allTests &&
                      adminInfo.allTests.map(item => item.testName).map((item, i) => {
                      return <MenuItem key={i} value={adminInfo.allTests[i].testId}>{item}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 150}} size="small">
                  <InputLabel id="langFilterLabel">Language</InputLabel>
                  <Select
                    labelId="langFilterLabel"
                    id="langFilter"
                    value={lang}
                    onChange={handleLangChange}
                    autoWidth
                    label="Language"
                  >
                    {/* hard coded lang to hindi on testId 5 */}
                    {testId!==5 && <MenuItem value="English">English</MenuItem>}
                    <MenuItem value="Hindi">Hindi</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 85}} size="small">
                  <InputLabel id="stdFilterLabel">Class</InputLabel>
                  <Select
                    labelId="stdFilterLabel"
                    id="stdFilter"
                    value={std}
                    onChange={handleStdChange}
                    autoWidth
                    label="Class"
                  >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem> */}
                    {/* getting all unique std values from data */}
                    { reportData &&
                      [...new Set(reportData.school.map(item => item.std))].sort().map((item, i) => {
                      return <MenuItem key={i} value={item}>{item}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
                {tabValue === 1 && 
                  <FormControl sx={{ m: 1, minWidth: 150}} size="small">
                    <InputLabel id="schoolFilterLabel">School</InputLabel>
                    <Select
                      labelId="schoolFilterLabel"
                      id="schoolFilter"
                      value={school}
                      onChange={handleSchoolChange}
                      autoWidth
                      label="School Name"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      { reportData &&
                        [...new Set(reportData.school.map(item => item.schoolName))].sort().map((item, i) => {
                        return <MenuItem key={i} value={item}>{item}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                }
              </div>
              {/* <div className="currentFilters">
                <Typography variant="body1" gutterBottom>
                  Current Filters: 
                </Typography>
                { adminInfo.allTests && 
                  <Chip 
                    variant="outlined" 
                    color="info"  
                    // onDelete={handleLangChipDelete} 
                    icon={<EditNoteIcon />}
                    label={adminInfo.allTests.filter(item=> item.testId === testId)[0].testName}
                    sx={{maxWidth: 150}} 
                  />
                }
                <Chip 
                  variant="outlined" 
                  color="info"  
                  // onDelete={handleLangChipDelete} 
                  icon={<TranslateIcon />}
                  label={lang}
                  // sx={{mr: 1}} 
                />
                {(std) && 
                  <Chip 
                    variant="outlined" 
                    color="info"  
                    // onDelete={handleStdChipDelete} 
                    icon={<PinIcon />}
                    label={std}
                  />
                }
                {(tabValue === 1 && school) && 
                  <Chip 
                    variant="outlined" 
                    color="info"  
                    onDelete={handleSchoolChipDelete} 
                    icon={<SchoolIcon />}
                    label={school}
                    sx={{mr: 1}} 
                  />
                }
              </div> */}
            </div>
            <div className="charts">
              {!reportError ?
                <>
                  {( reportData && tabValue === 0 ) &&
                    <BarChart
                      chartData = {lang === "English" ? 
                        [
                          [filteredReportData.allSchools.enWcpmCat1, filteredReportData.allSchools.enWcpmCat2, filteredReportData.allSchools.enWcpmCat3, filteredReportData.allSchools.enWcpmCat4, filteredReportData.allSchools.enWcpmCat5, filteredReportData.allSchools.enTotal], 
                          [filteredReportData.region.enWcpmCat1, filteredReportData.region.enWcpmCat2, filteredReportData.region.enWcpmCat3, filteredReportData.region.enWcpmCat4, filteredReportData.region.enWcpmCat5, filteredReportData.region.enTotal], 
                          filteredReportData.school.reduce((acc, item) => {
                            return [
                              acc[0] + item.enWcpmCat1,
                              acc[1] + item.enWcpmCat2,
                              acc[2] + item.enWcpmCat3,
                              acc[3] + item.enWcpmCat4,
                              acc[4] + item.enWcpmCat5,
                              acc[5] + item.enTotal,
                            ]
                          }, [0, 0, 0, 0, 0, 0])
                        ]
                      :
                        [
                          [filteredReportData.allSchools.hiWcpmCat1, filteredReportData.allSchools.hiWcpmCat2, filteredReportData.allSchools.hiWcpmCat3, filteredReportData.allSchools.hiWcpmCat4, filteredReportData.allSchools.hiWcpmCat5, filteredReportData.allSchools.hiTotal], 
                          [filteredReportData.region.hiWcpmCat1, filteredReportData.region.hiWcpmCat2, filteredReportData.region.hiWcpmCat3, filteredReportData.region.hiWcpmCat4, filteredReportData.region.hiWcpmCat5, filteredReportData.region.hiTotal], 
                          filteredReportData.school.reduce((acc, item) => {
                            return [
                              acc[0] + item.hiWcpmCat1,
                              acc[1] + item.hiWcpmCat2,
                              acc[2] + item.hiWcpmCat3,
                              acc[3] + item.hiWcpmCat4,
                              acc[4] + item.hiWcpmCat5,
                              acc[5] + item.hiTotal,
                            ]
                          }, [0, 0, 0, 0, 0, 0])
                        ]
                      }
                      catLabelData={lang === "English" ? 
                        [filteredReportData["thresholds"].enT1, filteredReportData["thresholds"].enT2, filteredReportData["thresholds"].enT3, filteredReportData["thresholds"].enT4]
                      :
                        [filteredReportData["thresholds"].hiT1, filteredReportData["thresholds"].hiT2, filteredReportData["thresholds"].hiT3, filteredReportData["thresholds"].hiT4]
                      }
                      labelData = {["All Schools", "Same Region Schools", "Your School"]}
                      title = {"WCPM - All Schools vs Same Region Schools vs Your School - " + lang} 
                      xLabel = {reportData.school.length ? "Grade " + std : "Grade"}
                      yLabel = "Student %"
                      chartInfo={lang==="English" ? 
                        (filteredReportData.school[0].enRecCount && filteredReportData.school.reduce((acc, item) => acc + (item.enRecCount - item.enTotal), 0)!==0) &&
                          // "Total number of students tested = " + filteredReportData.school.reduce((acc, item) => acc + item.enRecCount, 0).toLocaleString("hi") + 
                          filteredReportData.school.reduce((acc, item) => acc + (item.enRecCount - item.enTotal), 0).toLocaleString("hi") + 
                          " of " + filteredReportData.school.reduce((acc, item) => acc + item.enRecCount, 0).toLocaleString("hi") + 
                          " students tested are invalid and not considered in the results below."
                      : 
                        (filteredReportData.school[0].hiRecCount && filteredReportData.school.reduce((acc, item) => acc + (item.hiRecCount - item.hiTotal), 0)!==0) &&
                          filteredReportData.school.reduce((acc, item) => acc + (item.hiRecCount - item.hiTotal), 0).toLocaleString("hi") + 
                          " of " + filteredReportData.school.reduce((acc, item) => acc + item.hiRecCount, 0).toLocaleString("hi") +
                          " students tested are invalid and not considered in the results below."
                      }
                    />
                  }
                  {(reportData && tabValue === 1 && school !== "") ?
                    <BarChart 
                      chartData = {lang === "English" ?
                                    reportData.school
                                      .filter(item => item.schoolName === school && item.std === std)
                                      .map(item => [item.enWcpmCat1, item.enWcpmCat2, item.enWcpmCat3, item.enWcpmCat4, item.enWcpmCat5, item.enTotal])
                                    :
                                    reportData.school
                                    .filter(item => item.schoolName === school && item.std === std)
                                    .map(item => [item.hiWcpmCat1, item.hiWcpmCat2, item.hiWcpmCat3, item.hiWcpmCat4, item.hiWcpmCat5, item.hiTotal])
                                  }
                      catLabelData={lang === "English" ? 
                          [filteredReportData["thresholds"].enT1, filteredReportData["thresholds"].enT2, filteredReportData["thresholds"].enT3, filteredReportData["thresholds"].enT4]
                        :
                          [filteredReportData["thresholds"].hiT1, filteredReportData["thresholds"].hiT2, filteredReportData["thresholds"].hiT3, filteredReportData["thresholds"].hiT4]
                      }
                      labelData = {reportData.school
                                    .filter(item => item.schoolName === school && item.std === std)
                                    .map(item => item.std + item.divn)
                                  }
                      title = {"WCPM - Class Wise - " + lang} 
                      xLabel = "Classes"
                      yLabel = "Student %"
                      chartInfo={lang==="English" ? 
                        reportData.school[0].enRecCount && 
                          // "Total number of students tested = " + reportData.school.filter(item => item.schoolName === school && item.std === std).reduce((acc, item) => acc + item.enRecCount, 0).toLocaleString("hi") + 
                          reportData.school.filter(item => item.schoolName === school && item.std === std).reduce((acc, item) => acc + (item.enRecCount - item.enTotal), 0).toLocaleString("hi") + 
                          " of " + reportData.school.filter(item => item.schoolName === school && item.std === std).reduce((acc, item) => acc + item.enRecCount, 0).toLocaleString("hi") +
                          " students tested are invalid and not considered in the results below."
                      : 
                        reportData.school[0].hiRecCount && 
                          reportData.school.filter(item => item.schoolName === school && item.std === std).reduce((acc, item) => acc + (item.hiRecCount - item.hiTotal), 0).toLocaleString("hi") + 
                          " of " + reportData.school.filter(item => item.schoolName === school && item.std === std).reduce((acc, item) => acc + item.hiRecCount, 0).toLocaleString("hi") +
                          " students tested are invalid and not considered in the results below."
                      }
                    />
                    : (reportData && tabValue === 1) &&
                    <h3 style={{placeSelf: "center"}}>Select a School First</h3>
                  }
                  {!reportData && <CircularProgress color="info" sx={{placeSelf: "center"}}/> }
                </>
                : <h3 style={{placeSelf: "center"}}>{reportError}</h3>
              }
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default Report