import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4';
import { AppBar, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, Tooltip } from '@mui/material';
import axios from "axios"
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import FilterListIcon from '@mui/icons-material/FilterList';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import TranslateIcon from '@mui/icons-material/Translate';
// import AbcIcon from '@mui/icons-material/Abc';
// import PinIcon from '@mui/icons-material/Pin';
// import EditNoteIcon from '@mui/icons-material/EditNote';

import Table from "../../TableComponent";
import BarChart from '../../BarChart';

import { scaleLog } from '@visx/scale';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud';
import CompareReport from './CompareReport';

const Report = ({ facInfo }) => {
  const [reportData, setReportData] = useState("")
  const [reportError, setReportError] = useState("")
  const [testId, setTestId] = useState(facInfo.allTests ? facInfo.allTests[0].testId : null)
  // hard coded lang to hindi on testId 5
  const [lang, setLang] = useState(testId!==5 ? "English" : "Hindi");
  const [std, setStd] = useState("");
  const [divn, setDivn] = useState("");
  const [wordCloudData, setWordCloudData] = useState("")
  // const [words, setWords] = useState("")
  
  useEffect(() => {
    if(facInfo.allTests){
      Auth.currentSession()
      .then((data) => {
        const jwtToken = data.idToken.jwtToken;
  
        // const getReportData = async(facId, adminId, testId) => {
        //   try {
        //     const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/facilitator/"+facId+"/report?adminId="+adminId+"&testId="+testId, {
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': jwtToken
        //         }
        //     });
        //     // console.log("Report Data: ",response.data["report"])
        //     // adding full name by combining firstName and lastName
        //     const reportData = {...response.data["report"],
        //       "students": response.data["report"]["students"].map(stud => ({
        //       ...stud, 
        //       "fullName": stud["firstName"] + " " + stud["lastName"],
        //       "classSection": stud["std"] + "-" + stud["divn"],
        //       "enWcpm": stud["enWcpm"] === -3 ? "Not Tested" : stud["enWcpm"] === -2 ? "Audio Missing" : stud["enWcpm"] === -1 ? "Invalid Attempt" : stud["enWcpm"],
        //       "hiWcpm": stud["hiWcpm"] === -3 ? "Not Tested" : stud["hiWcpm"] === -2 ? "Audio Missing" : stud["hiWcpm"] === -1 ? "Invalid Attempt" : stud["hiWcpm"], 
        //       "enPhrasingScore": stud["enPhrasingScore"] === "-3.0" ? "Not Tested" : stud["enPhrasingScore"] === "-2.0"? "Audio Missing" : stud["enPhrasingScore"] === "-1.0" ? "Invalid Attempt" : stud["enPhrasingScore"]
        //     }))}
        //     // console.log(reportData)
        //     setReportData(reportData)
        //     setStd(reportData.class.map(item => item.std).sort()[0] || "")
        //     setDivn(reportData.class.map(item => item.divn).sort()[0] || "")
        //     if(!reportData.class.length) setReportError("Selected test does not contain results for your class!") 
        //   }
        //   catch (error) {
        //       console.log(error)
        //   }
        // }
  
        // const facId = facInfo.facilitator[0].facId;
        // const adminId = facInfo.facilitator[0].adminId;
        // getReportData(facId, adminId, testId)
        const getReportData = async(facId, adminId, classId, testId) => {
          try {
            const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/facilitator/"+facId+"/result?testId="+testId+"&classId="+classId, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                }
            });
            // console.log("Report Data: ",response.data["report"])
            
            const studentResponse =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/facilitator/"+facId+"/report?adminId="+adminId+"&testId="+testId, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
              }
            });
            const thresholds = response.data["report"]["thresholds"].filter(item => item.std === response.data["report"]["class"][0].std)[0]
            // console.log(thresholds)
            const reportData = {...response.data["report"],
              "students": studentResponse.data["report"]["students"].map(stud => ({
              ...stud, 
              "fullName": stud["firstName"] + " " + stud["lastName"],
              "classSection": stud["std"] + "-" + stud["divn"],
              // "enWcpm": stud["enWcpm"] === -3 ? "Not Tested" : stud["enWcpm"] === -2 ? "Audio Missing" : stud["enWcpm"] === -1 ? "Invalid Attempt" : stud["enWcpm"],
              // "hiWcpm": stud["hiWcpm"] === -3 ? "Not Tested" : stud["hiWcpm"] === -2 ? "Audio Missing" : stud["hiWcpm"] === -1 ? "Invalid Attempt" : stud["hiWcpm"], 
              // "enPhrasingScore": stud["enPhrasingScore"] === "-3.0" ? "Not Tested" : stud["enPhrasingScore"] === "-2.0"? "Audio Missing" : stud["enPhrasingScore"] === "-1.0" ? "Invalid Attempt" : stud["enPhrasingScore"],
              "enWcpmCat": thresholds.enT1 < 0 || stud.enWcpm < 0 ? "-" : 
                            stud.enWcpm <=thresholds.enT1 ? "WCPM between 0 - " + thresholds.enT1 :
                            stud.enWcpm <=thresholds.enT2 ? "WCPM between " + (thresholds.enT1+1) + " - " + thresholds.enT2 : 
                            stud.enWcpm <=thresholds.enT3 ? "WCPM between " + (thresholds.enT2+1) + " - " + thresholds.enT3 : 
                            thresholds.enT4 < 0 ? 
                              "WCPM >= " + (thresholds.enT3+1)
                            :
                              stud.enWcpm <= thresholds.enT4 ? "WCPM between " + (thresholds.enT3+1) + " - " + thresholds.enT4 : 
                              "WCPM >= " + (thresholds.enT4+1),
              "enWcpmColor": thresholds.enT1 < 0 ? "unset" :
                            stud.enWcpm === -3 ? "unset" :
                            stud.enWcpm < 0 ? "rgb(255 181 181 / 30%)" :                 // same as #FFB5B5 wcpm cat 1 chart color 
                            stud.enWcpm <=thresholds.enT1 ? "rgb(255 181 181 / 30%)" :
                            stud.enWcpm <=thresholds.enT2 ? "rgb(255 181 181 / 30%)" : 
                            thresholds.enT4 < 0 ? 
                              stud.enWcpm <=thresholds.enT3 ? "rgb(170 234 203 / 30%)" :        // same as #AAEACB wcpm cat 1 chart color
                              "rgb(170 234 203 / 30%)"
                            :
                              stud.enWcpm <=thresholds.enT3 ? "rgb(255 181 181 / 30%)" : 
                              stud.enWcpm <= thresholds.enT4 ? "rgb(170 234 203 / 30%)" : 
                              "rgb(170 234 203 / 30%)",
              "hiWcpmCat": thresholds.hiT1 < 0 || stud.hiWcpm < 0 ? "-" : 
                            stud.hiWcpm <=thresholds.hiT1 ? "WCPM between 0 - " + thresholds.hiT1 :
                            stud.hiWcpm <=thresholds.hiT2 ? "WCPM between " + (thresholds.hiT1+1) + " - " + thresholds.hiT2 : 
                            stud.hiWcpm <=thresholds.hiT3 ? "WCPM between " + (thresholds.hiT2+1) + " - " + thresholds.hiT3 : 
                            thresholds.hiT4 < 0 ? 
                              "WCPM >= " + (thresholds.hiT3+1)
                            :
                              stud.hiWcpm <= thresholds.hiT4 ? "WCPM between " + (thresholds.hiT3+1) + " - " + thresholds.hiT4 : 
                              "WCPM >= " + (thresholds.hiT4+1),
              "hiWcpmColor": thresholds.hiT1 < 0 ? "unset" :
                            stud.hiWcpm === -3 ? "unset" :
                            stud.hiWcpm < 0 ? "rgb(255 181 181 / 30%)" :
                            stud.hiWcpm <=thresholds.hiT1 ? "rgb(255 181 181 / 30%)" :
                            stud.hiWcpm <=thresholds.hiT2 ? "rgb(255 181 181 / 30%)" : 
                            thresholds.hiT4 < 0 ? 
                              stud.hiWcpm <=thresholds.hiT3 ? "rgb(170 234 203 / 30%)" : 
                              "rgb(170 234 203 / 30%)"
                            :
                              stud.hiWcpm <=thresholds.hiT3 ? "rgb(255 181 181 / 30%)" : 
                              stud.hiWcpm <= thresholds.hiT4 ? "rgb(170 234 203 / 30%)" : 
                              "rgb(170 234 203 / 30%)",
              "enWcpmPrevious": studentResponse.data["report"]["previousStudentResult"].length && studentResponse.data["report"]["previousStudentResult"].filter(item => item.studId === stud.studId)[0] 
                                ? studentResponse.data["report"]["previousStudentResult"].filter(item => item.studId === stud.studId)[0].enWcpm 
                                : "",                
              "enWcpmCatPrevious": studentResponse.data["report"]["previousStudentResult"].filter(item => item.studId === stud.studId).map(previousStud => (
                          thresholds.enT1 < 0 || previousStud.enWcpm < 0 ? "-" : 
                          previousStud.enWcpm <=thresholds.enT1 ? "WCPM between 0 - " + thresholds.enT1 :
                          previousStud.enWcpm <=thresholds.enT2 ? "WCPM between " + (thresholds.enT1+1) + " - " + thresholds.enT2 : 
                          previousStud.enWcpm <=thresholds.enT3 ? "WCPM between " + (thresholds.enT2+1) + " - " + thresholds.enT3 : 
                          thresholds.enT4 < 0 ? 
                            "WCPM >= " + (thresholds.enT3+1)
                          :
                            previousStud.enWcpm <= thresholds.enT4 ? "WCPM between " + (thresholds.enT3+1) + " - " + thresholds.enT4 : 
                            "WCPM >= " + (thresholds.enT4+1)
                        ))[0],
              "hiWcpmPrevious": studentResponse.data["report"]["previousStudentResult"].length && studentResponse.data["report"]["previousStudentResult"].filter(item => item.studId === stud.studId)[0]
                                ? studentResponse.data["report"]["previousStudentResult"].filter(item => item.studId === stud.studId)[0].hiWcpm 
                                : "",
              "hiWcpmCatPrevious": studentResponse.data["report"]["previousStudentResult"].filter(item => item.studId === stud.studId).map(previousStud => (
                          thresholds.hiT1 < 0 || previousStud.hiWcpm < 0 ? "-" : 
                          previousStud.hiWcpm <=thresholds.hiT1 ? "WCPM between 0 - " + thresholds.hiT1 :
                          previousStud.hiWcpm <=thresholds.hiT2 ? "WCPM between " + (thresholds.hiT1+1) + " - " + thresholds.hiT2 : 
                          previousStud.hiWcpm <=thresholds.hiT3 ? "WCPM between " + (thresholds.hiT2+1) + " - " + thresholds.hiT3 : 
                          thresholds.hiT4 < 0 ? 
                            "WCPM >= " + (thresholds.hiT3+1)
                          :
                            previousStud.hiWcpm <= thresholds.hiT4 ? "WCPM between " + (thresholds.hiT3+1) + " - " + thresholds.hiT4 : 
                            "WCPM >= " + (thresholds.hiT4+1)
                        ))[0],
            }))}
            // console.log(reportData)
            setStd(reportData.class.map(item => item.std).sort()[0] || "")
            setDivn(reportData.class.map(item => item.divn).sort()[0] || "")
            setWordCloudData(response.data.wordCloud)
            // if(lang==="English") setWords(response.data.wordCloud.EN)
            // else setWords(response.data.wordCloud.HI)
            if(!reportData.class.length) setReportError("Selected test does not contain results for your class!") 
            setReportData(reportData)
          }
          catch (error) {
            console.log(error)
            if(error.response.status === 404) setReportError("Selected test does not contain results for your class!")
            else console.log(error)
          }
        }
  
        const facId = facInfo.facilitator[0].facId;
        const adminId = facInfo.facilitator[0].adminId;
        const classId = facInfo.facilitator[0].schools[0].classes[0].classId
        getReportData(facId, adminId, classId, testId)
      })
      .catch((err) => console.log(err));
    }
    else{
      alert("No Test has been completed yet!\nRedirecting to Dashboard")
      window.location.replace("/");
    }
    
  }, [facInfo, testId]);

  const columnsEN = [
    {
      key: "fullName",
      headerName: "Student Name",
      width: 60,
      color: "enWcpmColor"
    },
    {
      key: "studId",
      headerName: "Student PEN",
      width: 80
    },
    // {
    //   key: "std",
    //   headerName: "Class",
    //   width: 30
    // },
    // { 
    //   key: "divn", 
    //   headerName: "Section", 
    //   width: 30 
    // },
    { 
      key: "classSection", 
      headerName: "Class", 
      width: 30 
    },
    { 
      key: "rollNo", 
      headerName: "Roll No", 
      width: 30 
    },
    { 
      key: "gender", 
      headerName: "Gender", 
      width: 30 
    },
    { 
      key: "enWcpm", 
      headerName: "WCPM (English)", 
      width: 80,
      tooltip: true
    },
    { 
      key: "enWcpmCat", 
      headerName: "WCPM Category", 
      width: 80,
      previousCategory: "enWcpmCatPrevious",
      previousWcpm: "enWcpmPrevious"
    },
    {
      key: "reportUrl",
      headerName: "Report",
      width: 60,
      dialogue: "report",
      testId: testId
    }
    // { 
    //   key: "enPhrasingScore", 
    //   headerName: "Phrasing (English)", 
    //   width: 80,
    //   tooltip: "Either student did not attempt atleast half of the story or all paragraphs in the story were not attempted" 
    // }
  ];

  const columnsHI = [
    {
      key: "fullName",
      headerName: "Student Name",
      width: 60,
      color: "hiWcpmColor"
    },
    {
      key: "studId",
      headerName: "Student PEN",
      width: 80
    },
    // {
    //   key: "std",
    //   headerName: "Class",
    //   width: 30
    // },
    // { 
    //   key: "divn", 
    //   headerName: "Section", 
    //   width: 30 
    // },
    { 
      key: "classSection", 
      headerName: "Class", 
      width: 30 
    },
    { 
      key: "rollNo", 
      headerName: "Roll No", 
      width: 30 
    },
    { 
      key: "gender", 
      headerName: "Gender", 
      width: 30 
    },
    { 
      key: "hiWcpm", 
      headerName: "WCPM (Hindi)", 
      width: 80,
      tooltip: true 
    },
    { 
      key: "hiWcpmCat", 
      headerName: "WCPM Category", 
      width: 80,
      previousCategory: "hiWcpmCatPrevious",
      previousWcpm: "hiWcpmPrevious"
    },
    {
      key: "reportUrl",
      headerName: "Report",
      width: 60,
      dialogue: "report",
      testId: testId
    }
    // { 
    //   key: "hiPhrasingScore", 
    //   headerName: "Phrasing (Hindi)", 
    //   width: 80,
    //   tooltip: "Either student did not attempt atleast half of the story or all paragraphs in the story were not attempted" 
    // }
  ];

  const headersEN = [
    { label: "Class", key: "std" },
    { label: "Section", key: "divn" },
    { label: "Student Name", key: "fullName" },
    { label: "Gender", key: "gender" },
    { label: "Student PEN (Maximum 12 digit)", key: "studId"},
    { label: "Roll No", key: "rollNo" },
    { label: "WCPM (English)", key: "enWcpm" },
    { label: "WCPM Category (English)", key: "enWcpmCat" },
    // { label: "Phrasing (English)", key: "enPhrasingScore" }
  ];

  const headersHI = [
    { label: "Class", key: "std" },
    { label: "Section", key: "divn" },
    { label: "Student Name", key: "fullName" },
    { label: "Gender", key: "gender" },
    { label: "Student PEN (Maximum 12 digit)", key: "studId"},
    { label: "Roll No", key: "rollNo" },
    { label: "WCPM (Hindi)", key: "hiWcpm" },
    { label: "WCPM Category (Hindi)", key: "hiWcpmCat" },
    // { label: "Phrasing (Hindi)", key: "hiPhrasingScore" }
  ];

  // const orderBy = ["rollNo"];
  const orderByEN = ["rollNo", "enWcpm"];
  const orderByHI = ["rollNo", "hiWcpm"];


  const [tabValue, setTabValue] = useState(0);
  // const [wordCloudError, setWordCloudError] = useState("")

  const handleTabChange = (event, newValue) => {
    // if(!reportError && newValue===2){
    //   setWordCloudError("")
    //   if(lang==="English") wordCloudData.EN.length ? setWords(wordCloudData.EN) : setWordCloudError("Word Cloud does not exist for your class!")
    //   else wordCloudData.HI.length ? setWords(wordCloudData.HI) : setWordCloudError("Word Cloud does not exist for your class!")
    // }
    // if(!reportError && wordCloudData === "" && newValue===2){
    //   Auth.currentSession()
    //   .then((data) => {
    //     const jwtToken = data.idToken.jwtToken;

    //     const getWordCloudData = async(facId, testId, classId) => {
    //       try {
    //         const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/facilitator/"+facId+"/word-cloud?testId="+testId+"&classId="+classId, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': jwtToken
    //             }
    //         });
    //         // console.log(response.data)
    //         setWordCloudData(response.data.wordCloud)
    //         if(lang==="English") setWords(response.data.wordCloud.EN)
    //         else setWords(response.data.wordCloud.HI)
    //       }
    //       catch (error) {
    //         if(error.response.status === 404) setWordCloudError("Word Cloud does not exist for your class!")
    //         else console.log(error)
    //       }
    //     }
    //     const facId = facInfo.facilitator[0].facId;
    //     const classId = facInfo.facilitator[0].schools[0].classes.filter(item => item.std === std && item.divn === divn)[0].classId
    //     getWordCloudData(facId, testId, classId)
    //   })
    //   .catch((err) => console.log(err));
    // }

    setTabValue(newValue);
    // console.log(event.target.firstChild.data)
    ReactGA.event({
      category: 'Facilitator Results Tab',
      action: 'Result Tab Switch',
      label: event.target.firstChild.data
    });
  };

  const handleTestIdChange = (e) => {
    setStd("");
    setDivn("");
    setReportData("");
    setReportError("");
    setWordCloudData("");
    setTestId(e.target.value);
  };

  const handleStdChange = (e) => {
    setStd(e.target.value);
  };

  const handleDivnChange = (e) => {
    setDivn(e.target.value);
  };

  const handleLangChange = (e) => {
    setLang(e.target.value);
    // if(!reportError && tabValue === 2){
    //   setWordCloudError("")
    //   if(e.target.value==="English") wordCloudData.EN.length ? setWords(wordCloudData.EN) : setWordCloudError("Word Cloud does not exist for your class!")
    //   else wordCloudData.HI.length ? setWords(wordCloudData.HI) : setWordCloudError("Word Cloud does not exist for your class!")
    // }
  };

  var filteredReportData
  if(std==="" && divn===""){
    filteredReportData = reportData
    // filteredSchoolFacInfo
  }
  // else if(std!=="" && divn===""){
  //   filteredReportData = {
  //     "allSchools": {...reportData.allSchools.filter(item => item.std === std)},
  //     "region": {...reportData.region.filter(item => item.std === std)},
  //     "class": {...reportData.class.filter(item => item.std === std)},
  //     "students": {...reportData.students.filter(item => item.std === std)}
  //   }
  // }
  // else if(std==="" && divn!==""){
  //   filteredReportData = [...reportData.class.filter(item => item.divn === divn)]
  // }
  else{
    filteredReportData = {
      "allSchools": reportData.allSchools.filter(item => item.std === std)[0],
      "region": reportData.region.filter(item => item.std === std)[0],
      "class": reportData.class.filter(item => item.std === std && item.divn === divn)[0],
      "students": reportData.students.filter(item => item.std === std && item.divn === divn),
      "thresholds": reportData["thresholds"].filter(item => item.std === std)[0]
    }
  }
  // console.log(filteredReportData)

  const colors = ['#143059', '#2F6B9A', '#82a6c2']
  var fontScale = ""
  var words = ""
  if(!reportError && wordCloudData && tabValue === 2) {
    // setWordCloudError("")
    if(lang==="English"){
      if(wordCloudData.EN.length){
        words = wordCloudData.EN 
        fontScale = scaleLog({
          domain: [Math.min(...words.map((w) => w.value)), Math.max(...words.map((w) => w.value))],
          range: [10, 80],
        });
      }  
      else words = ""
    } 
    else {
      if(wordCloudData.HI.length){
        words = wordCloudData.HI
        fontScale = scaleLog({
          domain: [Math.min(...words.map((w) => w.value)), Math.max(...words.map((w) => w.value))],
          range: [10, 80],
        });
      } 
      else words = ""
    }
  }

  return (
    <div className='report facilitatorReport innerContent'>
      <h2 style={{textAlign: "center", color: "#0288D1", borderRadius: "15px", border: "1px solid"}}>Results</h2>
      <div className="chartArea">
        <div >
          <AppBar position="static" sx={{mt: "0.5rem", backgroundColor: "#001162"}}>
            <Tabs value={tabValue} onChange={handleTabChange} sx={{minHeight: "30px"}} variant='fullWidth' textColor="inherit" TabIndicatorProps={{style: {backgroundColor: "#7ed46a", height: "4px"}}}>
              <Tab label="Overall" sx={{fontSize: "inherit", minHeight: "30px"}}/>
              <Tab label="Student Wise" sx={{fontSize: "inherit", minHeight: "30px"}}/>
              <Tab label="Practice Words" sx={{fontSize: "inherit", minHeight: "30px"}}/>
              <Tab label="Compare" sx={{fontSize: "inherit", minHeight: "30px"}}/>
            </Tabs>
          </AppBar>
          { tabValue !== 3 ?
              <div style={{display: "flex", flexDirection: "column", paddingTop: "0.5rem"}}>
                {/* <h4 style={{color: "#dc3545"}}>Note: Invalid attempts are not considered in the results.</h4> */}
                <p style={{fontSize: "14px", color: "#dc3545"}}>
                  Results generated based on recordings received till {facInfo.allTests && 
                    new Date(facInfo.allTests.filter(item=> item.testId === testId)[0].uploadEndDate).toLocaleDateString("en-GB", { dateStyle: "long" })}
                </p>
              </div>
            :
              <div style={{display: "flex", flexDirection: "column", paddingTop: "0.5rem"}}>
                <h4 style={{color: "#dc3545"}}>Note: Students considered for comparison have valid attempts in both tests & were in the same school, class & section in both tests (Transferred students are not considered)</h4>
              </div>
          }
        </div>
        { tabValue === 3 ?
          <CompareReport facInfo={facInfo}/>
        :
          <>
            <div className="filters">
              <div className="forms">
                <h4 style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem"}}>
                  <FilterListIcon fontSize="small"/>
                  <span style={{height: "100%"}}>Filters:</span> 
                </h4>
                <FormControl sx={{ m: 1, minWidth: 150}} size="small">
                  <InputLabel id="testNameFilterLabel">Test Name</InputLabel>
                  <Select
                    labelId="testIdFilterLabel"
                    id="testIdFilter"
                    value={testId ? testId : ""}
                    onChange={handleTestIdChange}
                    autoWidth
                    label="Test Name"
                  >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem> */}
                    {/* getting all unique std values from data */}
                    {facInfo.allTests &&
                      facInfo.allTests.map(item => item.testName).map((item, i) => {
                      return <MenuItem key={i} value={facInfo.allTests[i].testId}>{item}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120}} size="small">
                  <InputLabel id="langFilterLabel">Language</InputLabel>
                  <Select
                    labelId="langFilterLabel"
                    id="langFilter"
                    value={lang}
                    onChange={handleLangChange}
                    autoWidth
                    label="Language"
                  >
                    {/* hard coded lang to hindi on testId 5 */}
                    {testId!==5 && <MenuItem value="English">English</MenuItem>} 
                    <MenuItem value="Hindi">Hindi</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 85}} size="small">
                  <InputLabel id="stdFilterLabel">Class</InputLabel>
                  <Select
                    labelId="stdFilterLabel"
                    id="stdFilter"
                    value={std}
                    onChange={handleStdChange}
                    autoWidth
                    label="Class"
                  >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem> */}
                    {/* getting all unique std values from data */}
                    {reportData &&
                      [...new Set(reportData.class.map(item => item.std))].sort().map((item, i) => {
                      return <MenuItem key={i} value={item}>{item}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                  <InputLabel id="divnFilterLabel">Section</InputLabel>
                  <Select
                    labelId="divnFilterLabel"
                    id="divnFilter"
                    value={divn}
                    onChange={handleDivnChange}
                    autoWidth
                    label="Section"
                  >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem> */}
                    {/* getting all unique divn values from data */}
                    {reportData &&
                      [...new Set(reportData.class.filter(item => item.std === std).map(item => item.divn))].sort().map((item, i) => {
                      return <MenuItem key={i} value={item}>{item}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              </div>
              {/* <div className="currentFilters">
                <Typography variant="body1" gutterBottom>
                  Current Filters: 
                </Typography>
                { facInfo.allTests && 
                  <Chip 
                    variant="outlined" 
                    color="info"  
                    // onDelete={handleLangChipDelete} 
                    icon={<EditNoteIcon />}
                    label={facInfo.allTests.filter(item=> item.testId === testId)[0].testName}
                    sx={{maxWidth: 150}} 
                  />
                }
                { (tabValue === 0 || tabValue === 2) &&
                  <Chip 
                    variant="outlined" 
                    color="info"  
                    // onDelete={handleLangChipDelete} 
                    icon={<TranslateIcon />}
                    label={lang}
                    // sx={{mr: 1}} 
                  />
                }
                {std && 
                  <Chip 
                    variant="outlined" 
                    color="info"  
                    // onDelete={handleStdChipDelete} 
                    icon={<PinIcon />}
                    label={std}
                  />
                }
                {divn &&
                  <Chip 
                    variant="outlined" 
                    color="info"  
                    // onDelete={handleDivnChipDelete} 
                    icon={<AbcIcon />}
                    label={divn} 
                    sx={{mr: 1}} 
                  />
                }
              </div> */}
            </div>
            {!reportError ? 
              <>
                {( reportData && tabValue === 0 ) &&
                  <div className="charts">
                    <BarChart
                      chartData = {lang === "English" ? 
                        [
                          [filteredReportData.allSchools.enWcpmCat1, filteredReportData.allSchools.enWcpmCat2, filteredReportData.allSchools.enWcpmCat3, filteredReportData.allSchools.enWcpmCat4, filteredReportData.allSchools.enWcpmCat5, filteredReportData.allSchools.enTotal],
                          [filteredReportData.region.enWcpmCat1, filteredReportData.region.enWcpmCat2, filteredReportData.region.enWcpmCat3, filteredReportData.region.enWcpmCat4, filteredReportData.region.enWcpmCat5, filteredReportData.region.enTotal], 
                          [filteredReportData.class.enWcpmCat1, filteredReportData.class.enWcpmCat2, filteredReportData.class.enWcpmCat3, filteredReportData.class.enWcpmCat4, filteredReportData.class.enWcpmCat5, filteredReportData.class.enTotal]
                        ]
                        :
                        [
                          [filteredReportData.allSchools.hiWcpmCat1, filteredReportData.allSchools.hiWcpmCat2, filteredReportData.allSchools.hiWcpmCat3, filteredReportData.allSchools.hiWcpmCat4, filteredReportData.allSchools.hiWcpmCat5, filteredReportData.allSchools.hiTotal],
                          [filteredReportData.region.hiWcpmCat1, filteredReportData.region.hiWcpmCat2, filteredReportData.region.hiWcpmCat3, filteredReportData.region.hiWcpmCat4, filteredReportData.region.hiWcpmCat5, filteredReportData.region.hiTotal], 
                          [filteredReportData.class.hiWcpmCat1, filteredReportData.class.hiWcpmCat2, filteredReportData.class.hiWcpmCat3, filteredReportData.class.hiWcpmCat4, filteredReportData.class.hiWcpmCat5, filteredReportData.class.hiTotal]
                        ]
                      }
                      catLabelData={lang === "English" ? 
                        [filteredReportData["thresholds"].enT1, filteredReportData["thresholds"].enT2, filteredReportData["thresholds"].enT3, filteredReportData["thresholds"].enT4]
                      :
                        [filteredReportData["thresholds"].hiT1, filteredReportData["thresholds"].hiT2, filteredReportData["thresholds"].hiT3, filteredReportData["thresholds"].hiT4]
                      }
                      labelData = {["All Schools", "Same Region Schools", "Your Class"]}
                      title = {"WCPM - All Schools vs Same Region Schools vs Your Class - " + lang}
                      xLabel = {"Grade " + std}
                      yLabel = "Student %"
                      chartInfo={lang==="English" ? 
                        (filteredReportData.class.enRecCount && (filteredReportData.class.enRecCount - filteredReportData.class.enTotal)!==0) && 
                          // "Total number of students tested = " + filteredReportData.class.enRecCount.toLocaleString("hi") + 
                          (filteredReportData.class.enRecCount - filteredReportData.class.enTotal).toLocaleString("hi") + 
                          " of " + filteredReportData.class.enRecCount.toLocaleString("hi") +
                          " students tested are invalid and not considered in the results below."
                      : 
                        (filteredReportData.class.hiRecCount && (filteredReportData.class.hiRecCount - filteredReportData.class.hiTotal)!==0) && 
                          (filteredReportData.class.hiRecCount - filteredReportData.class.hiTotal).toLocaleString("hi") +
                          " of " + filteredReportData.class.hiRecCount.toLocaleString("hi") +
                          " students tested are invalid and not considered in the results below."
                      }
                    />
                  </div>
                }
                {( reportData && tabValue === 1) &&
                  <div className="studentsContent">
                    <div className="tabs" style={{paddingTop: "0.5rem", justifyContent: "space-between"}}>
                      <Button variant="contained" color="info" startIcon={<FileDownloadIcon />} sx={{ placeSelf: "start", minWidth: "fit-content" }}>
                        {lang === "English" ? 
                          <CSVLink 
                            data={filteredReportData.students.sort((a, b) => a.enWcpm >= b.enWcpm ? 1 : -1).map(stud => ({...stud,
                              "enWcpm": stud["enWcpm"] === -3 ? "Not Tested" : stud["enWcpm"] === -2 ? "Audio Missing" : stud["enWcpm"] === -1 ? "Invalid Attempt" : stud["enWcpm"]
                            })).concat([
                                { "std": "", "divn": "", "fullName": "", "gender": "", "studId": "", "rollNo": "", "enWcpm": "", "enWcpmCat": ""},
                                { "std": "Teacher's Sign:", "divn": "", "fullName": "", "gender": "", "studId": "", "rollNo": "", "enWcpm": "", "enWcpmCat": "Principal's Sign:"},
                            ])} 
                            headers={headersEN} filename={"StudentsResultData-English.csv"}>
                            Download as CSV
                          </CSVLink>
                        :
                          <CSVLink 
                            data={filteredReportData.students.sort((a, b) => a.hiWcpm > b.hiWcpm ? 1 : -1).map(stud => ({...stud,
                              "hiWcpm": stud["hiWcpm"] === -3 ? "Not Tested" : stud["hiWcpm"] === -2 ? "Audio Missing" : stud["hiWcpm"] === -1 ? "Invalid Attempt" : stud["hiWcpm"]
                            })).concat([
                              { "std": "", "divn": "", "fullName": "", "gender": "", "studId": "", "rollNo": "", "hiWcpm": "", "hiWcpmCat": ""},
                              { "std": "Teacher's Sign:", "divn": "", "fullName": "", "gender": "", "studId": "", "rollNo": "", "hiWcpm": "", "hiWcpmCat": "Principal's Sign:"},
                            ])} 
                            headers={headersHI} filename={"StudentsResultData-Hindi.csv"}>
                            Download as CSV
                          </CSVLink>
                        }
                      </Button>
                      <div style={{display: "flex", flexWrap: "wrap", alignItems: "center", gap: "0.5rem"}}>
                        <div style={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
                          <span style={{backgroundColor: "rgb(255 181 181 / 30%)", display: "inline-flex", width: "40px", height: "15px"}}></span>
                          <p className="fontFamily-Montserrat">Intervention Category 1</p>
                        </div>
                        <div style={{display: "flex", alignItems: "center", gap: "0.5rem", marginLeft: "0.5rem"}}>
                          <span style={{backgroundColor: "rgb(170 234 203 / 30%)", display: "inline-flex", width: "40px", height: "15px"}}></span>
                          <p className="fontFamily-Montserrat">Intervention Category 2</p>
                        </div>
                      </div>
                    </div>
                    <Divider />
                    <div className="table">
                      <Table
                        columns={lang === "English" ? columnsEN : columnsHI}
                        tableData={filteredReportData.students}
                        orderBy={lang === "English" ? orderByEN : orderByHI}
                        // tableHeight="47vh" 
                      />
                    </div>
                  </div>
                }
                {/* {(!reportData || (!wordCloudData && !wordCloudError && tabValue === 2))&& <div className="charts"><CircularProgress color="info"/></div> } */}
                {/* {(wordCloudError && tabValue === 2) && <div className="charts"><h3>{wordCloudError}</h3></div> } */}
                {(wordCloudData && tabValue === 2) && 
                  <>
                  {words ? 
                    <div className="wordCloud">
                      <Wordcloud
                        words={words}
                        width={800}
                        height={480}
                        fontSize={fontScale && ((w) =>  fontScale(w.value))}
                        font={'Impact'}
                        padding={4}
                        // spiral={"rectangular"}
                        rotate={0}
                        random={() => 0.5}
                      >
                        {(cloudWords) =>
                          cloudWords.map((w, i) => (
                            <Tooltip title={w.text + ": " + w.value} key={w.text} arrow>
                              <text
                                key={w.text}
                                fill={colors[i % colors.length]}
                                textAnchor={'middle'}
                                transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                                fontSize={w.size}
                                fontFamily={w.font}
                              >
                                {w.text}
                              </text>
                            </Tooltip>
                          ))
                        }
                      </Wordcloud>
                    </div>
                  :
                    <div className="charts" style={{alignItems: "center"}}><h3>Word Cloud does not exist for selected language!</h3></div>
                  }</>
                }
                {(!reportData)&& <div className="charts" style={{alignItems: "center"}}><CircularProgress color="info" /></div> }
              </>
              : <div className="charts" style={{alignItems: "center"}}><h3 style={{placeSelf: "center"}}>{reportError}</h3></div>
            }
          </>
        }
      </div>
    </div>
  )
}

export default Report