import React, { useState } from 'react'
import { AlertTitle, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import axios from "axios"
import { Auth } from "aws-amplify";
import MuiAlert from '@mui/material/Alert';
import FilterListIcon from '@mui/icons-material/FilterList';

import BarChart from '../../BarChart';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

const CompareReport = ({ adminInfo }) => {
  const [toastOpen, setToastOpen] = useState(false);
  const [toastType, setToastType] = useState([]);
  const [reportData, setReportData] = useState({})
  const [reportState, setReportState] = useState(false)
  const [compareState, setCompareState] = useState(true)
  const [reportError, setReportError] = useState("")
  const [testId, setTestId] = useState(["", ""])

  const [std, setStd] = useState("");
  // hard coded lang to hindi on testId 5
  const [lang, setLang] = useState(testId!==5 ? "English" : "Hindi");

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') return
    setToastOpen(false);
  };

  const handleTestIdChange = (index, testId) => {
    setCompareState(true)
    setTestId(prev => prev.map((oldTestId, i) => {
      if(i===index) return testId
      else return oldTestId
    }));
  };

  const handleStdChange = (e) => {
    setStd(e.target.value);
  };

  const handleLangChange = (e) => {
    setLang(e.target.value);
  };

  var filteredReportData = ""
  if(std!== "" && !compareState) filteredReportData = {
    [testId[0]] : {
      "school": reportData[testId[0]].report.school.filter(item => item.std === std),
      "thresholds": reportData[testId[0]].report.thresholds.filter(item => item.std === std)[0]
    },
    [testId[1]] : {
      "school": reportData[testId[1]].report.school.filter(item => item.std === std),
      "thresholds": reportData[testId[1]].report.thresholds.filter(item => item.std === std)[0]
    }
  }

  const handleCompare = () => {

    if(!adminInfo.allTests && adminInfo.allTests.length===1){
      setToastType(["warning", "Not enough completed test to compare!"])
      setToastOpen(true)
      return
    }

    if(!(testId[0]) || !(testId[1])) {
      setToastType(["warning", "Please fill all the required fields!"])
      setToastOpen(true)
      return
    }

    const testId1 = String(testId[0])
    const testId2 = String(testId[1])

    let oldTestIds = Object.keys(reportData)
    let newTestIds = []

    if(!oldTestIds.includes(testId1)) newTestIds.push(testId1)
    if(!oldTestIds.includes(testId2)) newTestIds.push(testId2)

    // console.log(oldTestIds)
    // console.log(testId1, testId2)
    // console.log(newTestIds)

    setReportError("")
    if(newTestIds.length){
      setReportState(true)
      const adminId = adminInfo.admin.adminId
      Auth.currentSession()
      .then(async (data) => {
        const jwtToken = data.idToken.jwtToken;
  
        try {
          const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/admin/"+adminId+"/compare-result?testId="+testId.toString(), {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': jwtToken
            }
          });
          // console.log("get Response: ",response.data)
          const responseData = response.data["report"]
          setReportData({...responseData})
          setStd([...new Set(responseData[testId[0]].report.school.map(item => item.std))].filter(item => 
            [...new Set(responseData[testId[1]].report.school.map(item => item.std))].includes(item)
          ).sort()[0])
          setReportState(false)
          setCompareState(false)
        }
        catch (error) {
          if(error.response.status === 404) setReportError("Result not found for selected tests!")
          else{
            console.log(error)
            setToastType(["error", "Error in getting results!"])
            setToastOpen(true)
          }
          setReportState(false)
        }
      })
      .catch((err) => console.log(err));
    }
    else setCompareState(false)
  }

  return (
    <>
      <Snackbar open={toastOpen} autoHideDuration={5000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} severity={toastType[0]} sx={{ width: '100%' }}>
        {toastType[1]}
        </Alert>
      </Snackbar>
      <div className="filters" style={{flexDirection: "column", alignItems: "unset"}}>
        <div className="forms">
          <FormControl sx={{ m: 1, minWidth: 150}} size="small">
            <InputLabel id="test1FilterLabel">Test 1</InputLabel>
            <Select
              labelId="test1FilterLabel"
              id="test1Filter"
              value={testId[0] ? testId[0] : ""}
              onChange={(e) => handleTestIdChange(0, e.target.value)}
              autoWidth
              label="Test Name"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {/* getting all unique std values from data */}
              {adminInfo.allTests &&
                adminInfo.allTests.map(item => item.testName).map((item, i) => {
                return <MenuItem key={i} value={adminInfo.allTests[i].testId} disabled={testId[1] === adminInfo.allTests[i].testId}>{item}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 150}} size="small">
            <InputLabel id="test2FilterLabel">Test 2</InputLabel>
            <Select
              labelId="test2FilterLabel"
              id="test2Filter"
              value={testId[1] ? testId[1] : ""}
              onChange={(e) => handleTestIdChange(1, e.target.value)}
              autoWidth
              label="Test Name"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {/* getting all unique std values from data */}
              {adminInfo.allTests &&
                adminInfo.allTests.map(item => item.testName).map((item, i) => {
                return <MenuItem key={i} value={adminInfo.allTests[i].testId} disabled={testId[0] === adminInfo.allTests[i].testId}>{item}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          {reportState 
            ?
            <Button color="info" variant="contained" disabled sx={{m: 1}}>Compare<CircularProgress size="1rem" color="inherit" sx={{marginLeft: "0.25rem"}}/></Button>
            :
            <Button color="info" variant="contained" onClick={handleCompare} sx={{m: 1}}>Compare</Button>
          }
        </div>
        {!compareState ?
          <div className="forms">
            <h4 style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem"}}>
              <FilterListIcon fontSize="small"/>
              <span style={{height: "100%"}}>Filters:</span> 
            </h4>
            <FormControl sx={{ m: 1, minWidth: 150}} size="small">
              <InputLabel id="langFilterLabel">Language</InputLabel>
              <Select
                labelId="langFilterLabel"
                id="langFilter"
                value={lang}
                onChange={handleLangChange}
                autoWidth
                label="Language"
              >
                {/* hard coded lang to hindi on testId 5 */}
                {testId!==5 && <MenuItem value="English">English</MenuItem>}   
                <MenuItem value="Hindi">Hindi</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 85}} size="small">
              <InputLabel id="stdFilterLabel">Class</InputLabel>
              <Select
                labelId="stdFilterLabel"
                id="stdFilter"
                value={std}
                onChange={handleStdChange}
                autoWidth
                label="Class"
              >
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                {/* getting all unique std values from data */}
                { Object.keys(reportData).length &&
                  [...new Set(reportData[testId[0]].report.school.map(item => item.std))].filter(item => 
                    [...new Set(reportData[testId[1]].report.school.map(item => item.std))].includes(item)
                  ).sort().map((item, i) => {
                  return <MenuItem key={i} value={item}>{item}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </div>
        :
          <MuiAlert severity="info" className="uploadInstructions" style={{marginTop: "0.5rem"}}>
            <AlertTitle><b>Select the two tests that you would like to compare results for and click on "Compare".</b></AlertTitle>
          </MuiAlert>
        }
      </div>
      <div className="charts">
        {!reportError ? 
          <>
            {filteredReportData && !compareState &&
              <BarChart
                // hard coded benchmarks for rajashthan testId = 5
                chartData = {lang === "English" ? 
                  [filteredReportData[testId[0]].school.reduce((acc, item) => {
                      return [
                        acc[0] + item.enWcpmCat1,
                        acc[1] + item.enWcpmCat2,
                        acc[2] + item.enWcpmCat3,
                        acc[3] + item.enWcpmCat4,
                        acc[4] + item.enWcpmCat5,
                        acc[5] + item.enTotal,
                      ]
                    }, [0, 0, 0, 0, 0, 0]),
                    filteredReportData[testId[1]].school.reduce((acc, item) => {
                      return [
                        acc[0] + item.enWcpmCat1,
                        acc[1] + item.enWcpmCat2,
                        acc[2] + item.enWcpmCat3,
                        acc[3] + item.enWcpmCat4,
                        acc[4] + item.enWcpmCat5,
                        acc[5] + item.enTotal,
                      ]
                    }, [0, 0, 0, 0, 0, 0])
                  ]
                :
                  [filteredReportData[testId[0]].school.reduce((acc, item) => {
                      return [
                        acc[0] + item.hiWcpmCat1,
                        acc[1] + item.hiWcpmCat2,
                        acc[2] + item.hiWcpmCat3,
                        acc[3] + item.hiWcpmCat4,
                        acc[4] + item.hiWcpmCat5,
                        acc[5] + item.hiTotal,
                      ]
                    }, [0, 0, 0, 0, 0, 0]),
                    filteredReportData[testId[1]].school.reduce((acc, item) => {
                      return [
                        acc[0] + item.hiWcpmCat1,
                        acc[1] + item.hiWcpmCat2,
                        acc[2] + item.hiWcpmCat3,
                        acc[3] + item.hiWcpmCat4,
                        acc[4] + item.hiWcpmCat5,
                        acc[5] + item.hiTotal,
                      ]
                    }, [0, 0, 0, 0, 0, 0])
                  ]
                }
                catLabelData={lang === "English" ? 
                    [filteredReportData[testId[0]].thresholds.enT1, filteredReportData[testId[0]].thresholds.enT2, filteredReportData[testId[0]].thresholds.enT3, filteredReportData[testId[0]].thresholds.enT4]
                  :
                    [filteredReportData[testId[0]].thresholds.hiT1, filteredReportData[testId[0]].thresholds.hiT2, filteredReportData[testId[0]].thresholds.hiT3, filteredReportData[testId[0]].thresholds.hiT4]
                }
                labelData = {["Your School - " + adminInfo.allTests.filter(item => item.testId === testId[0])[0].testName, 
                              "Your School - " + adminInfo.allTests.filter(item => item.testId === testId[1])[0].testName
                            ]}
                title = {"WCPM - Comparison - " + lang} 
                xLabel = {"Grade " + std}
                yLabel = "Student %"
              />
            }
            {reportState && compareState && <CircularProgress color="info" sx={{placeSelf: "center"}}/> }
          </>
        : <h3 style={{placeSelf: "center"}}>{reportError}</h3>
      }
      </div>
    </>
  )
}

export default CompareReport