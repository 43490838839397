import React, { useEffect } from "react";
import ReactDOM from 'react-dom';
import { Box, Divider, List } from "@mui/material";
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import DashboardIcon from "@mui/icons-material/Dashboard";
import SchoolIcon from "@mui/icons-material/School";
import MapIcon from '@mui/icons-material/Map';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ReportIcon from '@mui/icons-material/Report';
// import EditNoteIcon from '@mui/icons-material/EditNote';
import RateReviewIcon from '@mui/icons-material/RateReview';

import SidebarListItem from "../../SidebarListItem";

const Sidebar = () => {
  
  useEffect(()=>{
    if(window.screen.width<=1100){
      ReactDOM.findDOMNode(document.querySelector(".mainContent .sidebarContent")).style.display = "none"
    }
  }, [])

  const handleClickAway = (e) => {
    if(window.screen.width<=1100){
      const sidebarElement = ReactDOM.findDOMNode(document.querySelector(".mainContent .sidebarContent"))
      if(e.clientY < 64 && e.clientX < 80){
        const mainContentHeight = ReactDOM.findDOMNode(document.querySelector(".mainContent")).clientHeight
        sidebarElement.style.minHeight = mainContentHeight+"px"
      }
      if(sidebarElement.style.display==="flex" && e.clientY >= 64){
        sidebarElement.style.display = "none"
      }
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway} touchEvent={false}>
      <Box
        className = "sidebarContent"
      >
        <Divider 
          sx={{  
            boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            border: "1px solid rgb(84 74 74 / 54%)"
          }} 
        />
        <List component="nav" aria-label="main dashboard">
          <SidebarListItem 
            name = "Dashboard"
            icon = {<DashboardIcon />}
            path = "/"
            />
        </List>
        <Divider />
        <List component="nav" aria-label="secondary recording details">
          <SidebarListItem 
            name = "Region"
            icon = {<MapIcon />}
            path = "/region"
          />        
          <SidebarListItem 
            name = "School"
            icon = {<SchoolIcon />}
            path = "/school"
          />
        </List>
        <Divider />
        <List component="nav" aria-label="results">
          <SidebarListItem 
            name = "Results"
            icon = {<AssessmentIcon />}
            path = "/results"
          />
        </List>
        <Divider />
        {/* <List component="nav" aria-label="test">
          <SidebarListItem 
            name = "Test"
            icon = {<EditNoteIcon />}
            path = "/test"
          />
        </List>
        <Divider /> */}
        <List component="nav" aria-label="Report Issue">
          <SidebarListItem 
            name = "Report Issue"
            icon = {<ReportIcon />}
            path = "https://forms.gle/fnU48UpGWWs5zgoT6"
            newTab = {true}
          />
        </List>
        <List component="nav" aria-label="Feedback Form">
          <SidebarListItem 
            name = "Feedback Form"
            icon = {<RateReviewIcon />}
            path = "https://forms.gle/gtKSxUUyumNnUD8D8"
            newTab = {true}
          />
        </List>
      </Box>
    </ClickAwayListener>
  );
};

export default Sidebar;
